.knotverse__roadmap {
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  padding-bottom: 4rem;
}

.knotverse__roadmap-heading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0rem 0 0rem;
}

.knotverse__roadmap-heading h1 {
  -webkit-text-fill-color: transparent;
  color: #fff;
  -webkit-text-stroke: .5px #fff;
  font-family: var(--font-family);
  font-weight: 800;
  text-align: left;
  text-transform: uppercase;
  font-size: 80px;
  line-height: 82px;
  margin: 5rem 0 5rem;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

.timeline {
  position: relative;
  width: 100%;
  max-width: full;
  margin: 0 auto;
  padding: 0px 0;
}

.timeline::after {
  content: '';
  position: absolute;
  width: 2px;
  background: #b50000;
  top: 0;
  bottom: 0;
  left: 50%;
  margin-left: -1px;
}

.container {
  padding: 5px 15px;
  position: relative;
  background: inherit;
  width: 50%;
}

.container.left {
  left: 0;
}

.container.right {
  left: 50%;
}

.container::after {
  content: '';
  position: absolute;
  width: 16px;
  height: 16px;
  top: calc(50% - 8px);
  right: -8px;
  background: #ffffff;
  border: 2px solid #b50000;
  border-radius: 16px;
  z-index: 1;
}

.container.right::after {
  left: -8px;
}

.container::before {
  content: '';
  position: absolute;
  width: 50px;
  height: 2px;
  top: calc(50% - 1px);
  right: 8px;
  background: #b50000;
  z-index: 1;
}

.container.right::before {
  left: 8px;
}

.container.left .date {
  right: -75px;
}

.container.right .date {
  left: -75px;
}

.container .icon {
  position: absolute;
  display: inline-block;
  width: 40px;
  height: 40px;
  padding: 9px 0;
  top: calc(50% - 20px);
  background: #000;
  filter: drop-shadow(0px 0px 20px rgba(130, 14, 14, 1));
  border: 2px solid #b50000;
  border-radius: 40px;
  text-align: center;
  font-size: 18px;
  color: #b50000;
  z-index: 1;
}

.container.left .icon {
  right: 56px;
}

.container.right .icon {
  left: 56px;
}

.container .content {
  padding: 30px 90px 30px 30px;
  background: #000;
  filter: drop-shadow(0px 0px 20px rgba(130, 14, 14, 1));
  position: relative;
}

.container.right .content {
  padding: 30px 30px 30px 90px;
}

.container .content h2 {
  margin: 0 0 10px 0;
  font-size: 20px;
  font-weight: 800;
  font-family: var(--font-family);
  text-transform: uppercase;
  letter-spacing: .25rem;
  color: #b50000;
}

.container .content p {
  margin: 0;
  font-size: 15px;
  line-height: 22px;
  color: #fff;
  font-family: var(--font-family);
  letter-spacing: 0.1rem;
  text-transform: uppercase;
}

@media (max-width: 700px) {
  .knotverse__roadmap-heading {
    padding: 2rem;
  }

  .knotverse__roadmap-heading h1 {
    font-size: 40px;
    line-height: 45px;
    margin: 5rem 0 2rem;
    text-align: center;
  }

  .container .content p {
    margin: 0;
    font-size: 12px;
    line-height: 22px;
  }

  .timeline::after {
    left: 5px;
  }

  .container {
    width: 100%;
    padding-left: 40px;
    padding-right: 20px;
  }

  .container.right {
    left: 0%;
  }

  .container.left::after,
  .container.right::after {
    left: 40px;
  }

  .container.left::before,
  .container.right::before {
    left: 5px;
    border-color: transparent #b50000 transparent transparent;
  } 

  .container.left .icon,
  .container.right .icon {
    display: none;
  }

  .container.left .content,
  .container.right .content {
    padding: 30px 30px 30px 30px;
  }
}