.knotverse__footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: var(--color-footer);
}

.knotverse__footer-links {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;

    text-align: left;
}

.knotverse__footer-links div {
    width: 250px;
    margin: 1rem;
}

.knotverse__footer-links_logo {
    display: flex;
    flex-direction: column;
}

.knotverse__footer-links_logo img {
    width: 150px;
    margin-bottom: 1rem;
    margin-top: 1rem;
}

.knotverse__footer-links_logo p {
    font-family: var(--font-family);
    font-size: 12px;
    line-height: 15px;
    color: #fff;
}

.knotverse__footer-links_div {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}

.knotverse__footer-links_div h4 {
    font-size: 14px;
    line-height: 17px;
    font-family: var(--font-family);
    color: #fff;

    margin-top: 1rem;
    margin-bottom: 0.9rem;
}

.knotverse__footer-links_div p {
    font-size: 12px;
    line-height: 15px;
    font-family: var(--font-family);
    color: #fff;

    margin: 0.5rem 0;
    cursor: pointer;
}

.knotverse__footer-copyright {
    margin-top: 2rem;
    text-align: center;
    width: 100%;
}

.knotverse__footer-copyright p {
    font-size: 12px;
    font-family: var(--font-family);
    line-height: 15px;
    color: #fff;
}

@media screen and (max-width: 850px) {
    .knotverse__footer-heading h1 {
        font-size: 44px;
        line-height: 50px;
    }
}

@media screen and (max-width: 550px) {
    .knotverse__footer-links_logo {
        display: none;
    }
    .knotverse__footer-links {
        margin: 0rem;
        justify-content: center;
        align-items: center;
    }
    .knotverse__footer-links h4 {
        font-size: 12px;
        line-height: 13px;
        margin-top: 0.75rem;
        margin-bottom: 0.5rem;
        text-align: center;
    }
    .knotverse__footer-links_div p {
        font-size: 10px;
        line-height: 11px;
        text-align: center;
    }
    .knotverse__footer-copyright {
        margin-top: 2rem;
        text-align: left;
        width: 100%;
    }
    .knotverse__footer-copyright p {
        text-align: center;
    }
}

@media screen and (max-width: 400px) {
    .knotverse__footer-links_logo {
        display: none;
    }
    .knotverse__footer-links {
        margin: 0rem;
        justify-content: center;
        align-items: center;
    }
    .knotverse__footer-links h4 {
        font-size: 12px;
        line-height: 13px;
        margin-top: 0.75rem;
        margin-bottom: 0.5rem;
        text-align: center;
    }
    .knotverse__footer-links_div p {
        font-size: 10px;
        line-height: 11px;
        text-align: center;
    }
    .knotverse__footer-copyright {
        margin-top: 2rem;
        text-align: left;
        width: 100%;
    }
    .knotverse__footer-copyright p {
        text-align: center;
    }
}