* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
}

body {
    background: var(--color-bg)
}

a {
    color: unset;
    text-decoration: none;
}

.moving__bg {
    background-image: url("./assets/Knotverse_gif_noText.gif");
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: 40% 40%;
}

.stock__bg {
    background-image: url("./assets/knotverseGifbw.gif");
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: 40% 40%;
}

.meta__bg {
    background-image: url("./assets/sandbox.jpg");
    background-size: contain;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: 100% 100%;
}

.gradient__bg {
 background:-moz-linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 50%, rgba(181, 0, 0, 1) 100%); 
 background:-webkit-linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 50%, rgba(181, 0, 0, 1) 100%);
 background:-o-linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 50%, rgba(181, 0, 0, 1) 100%);
 filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#B50000', endColorstr='#000000', GradientType=1 );
 background:-ms-linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 50%, rgba(181, 0, 0, 1) 100%);
 background:linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 50%, rgba(181, 0, 0, 1) 100%);
}

.gradient__text {
    background:var(--gradient-text);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.section__padding {
    padding: 4rem 0rem;
}

.section__margin {
    margin: 4rem 4rem;
}

.scale-up-center {
	-webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@-webkit-keyframes scale-up-center {
    0% {
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
}

@keyframes scale-up-center {
    0% {
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
}
  
@media screen and (max-width: 700px) {
    .section__padding {
        padding: 4rem;
    }

    .section__margin {
        margin: 4rem;
    }
}

@media screen and (max-width: 550px) {
    .section__padding {
        padding: 4rem 2rem;
    }
    
    .section__margin {
        margin: 4rem 2rem;
    }
}