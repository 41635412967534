.knotverse__anniversary {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.knotverse__anniversary h1 {
    -webkit-text-fill-color: transparent;
    color: #fff;
    -webkit-text-stroke: .5px #fff;
    font-family: var(--font-family);
    font-weight: 800;
    text-align: left;
    text-transform: uppercase;
    font-size: 60px;
    line-height: 64px;
    margin: 2rem 0 0rem;
}

.knotverse__anniversary h2 {
    font-family: var(--font-family);
    font-weight: 800;
    text-align: left;
    text-transform: uppercase;
    font-size: 30px;
    line-height: 40px;
    margin: .5rem 0 0rem;
}

.knotverse__anniversary-posters img {
    display: flex;
    flex-direction: column;
    width: 100%;
}

@media screen and (max-width: 550px) {
    .knotverse__anniversary {
        padding: 2rem;
    }
    .knotverse__anniversary h1 {
        font-size: 35px;
        line-height: 38px;
        text-align: center;
    }
    .knotverse__anniversary h2 {
        font-size: 18px;
        line-height: 20px;
        text-align: center;
    }
}

@media screen and (max-width: 400px) {
    .knotverse__anniversary {
        padding: 2rem;
    }
    .knotverse__anniversary h1 {
        font-size: 35px;
        line-height: 38px;
        text-align: center;
    }
    .knotverse__anniversary h2 {
        font-size: 18px;
        line-height: 20px;
        text-align: center;
    }
}