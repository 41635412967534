.knotverse__metaverse {
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(10px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 10rem;
}

.knotverse__metaverse-sec {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 3rem;
}

.knotverse__metaverse-sec img{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 100%;
}

.knotverse__metaverse-read button {
    padding: 0.5rem 1rem;
    color: #fff;
    background: #b50000; 
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 25px;
    text-transform: uppercase;
    line-height: 25px;
    border: none;
    outline: none;
    cursor: pointer;
}

@media screen and (max-width: 850px) {
    .knotverse__metaverse {
        padding-top: 0rem;
    }
    .knotverse__metaverse-sec img{
        padding: 2rem;
    }
}

@media screen and (max-width: 550px) {
    .knotverse__metaverse {
        padding: 0rem;
    }
    .knotverse__metaverse-sec img{
        padding: 2rem;
    }
}

@media screen and (max-width: 400px) {
    .knotverse__metaverse {
        margin-top: 4rem;
        margin-bottom: 4rem;
        padding: 0rem;
    }
    .knotverse__metaverse-sec img{
        padding: 2rem;
    }
}