.knotverse__faq-wrapper {
    padding-bottom: 5rem;
}

.knotverse__faq {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.knotverse__faq h1 {
    -webkit-text-fill-color: transparent;
    color: #fff;
    -webkit-text-stroke: .5px #fff;
    font-family: var(--font-family);
    font-weight: 800;
    text-align: left;
    text-transform: uppercase;
    font-size: 80px;
    line-height: 40px;
    margin: 5rem 0 .5rem;
}
 
.knotverse__faq-text h2 {
    color: #fff;
    font-family: var(--font-family);
    font-weight: 600;
    text-align: left;
    text-transform: uppercase;
    font-size: 30px;
    line-height: 40px;
    padding-left: 15%;
    padding-right: 15%;
    padding-top: 5%;
}

.knotverse__faq-text p {
    font-family: var(--font-family);
    font-weight: 400;
    text-align: left;
    max-width: 100%;
    font-size: 20px;
    line-height: 30px;
    color: #fff;
    padding-left: 15%; 
    padding-right: 15%;
    padding-top: 2%;
}

@media screen and (max-width: 550px) {
    .knotverse__faq h1{
        font-size: 35px;
        line-height: 40px;
        padding: 1rem;
        text-align: center;
    }
    .knotverse__faq-text h2{
        font-size: 20px;
        line-height: 25px;
        padding: 1rem;
        text-align: center;
    }
    .knotverse__faq-text p{
        font-size: 16px;
        line-height: 18px;
        padding: 1rem;
        text-align: center;
    }
} 