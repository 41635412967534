.knotverse__rewards {
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10rem;
}

.knotverse__rewards-text h2 {
    font-family: var(--font-family);
    font-weight: 800;
    text-align: center;
    text-transform: uppercase;
    font-size: 50px;
    line-height: 40px;
    margin: 1rem 0 2rem;
}

.knotverse__rewards-text p {
    font-family: var(--font-family);
    font-weight: 800;
    text-transform: uppercase;
    text-align: center;
    font-size: 30px;
    line-height: 40px;
    color: #fff;
    margin: 0rem 0 0rem;
}


@media screen and (max-width: 550px) {
    .knotverse__rewards {
        padding: 2rem;
    }
    .knotverse__rewards-text h2 {
        font-size: 35px;
        line-height: 37px;
        padding: 1rem;
    }
    .knotverse__rewards-text p {
        font-size: 15px;
        line-height: 20px;
        padding-bottom: 1rem;
    }
}