.knotverse__header {
    display: flex;
    flex-direction: row;
}

.knotverse__header-content {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 15rem;
    margin-bottom: 20rem;
}

.knotverse__header-content img {
    width: 800px;
    max-width: 100%;
    align-items: center;
}

.text-blur-out {
	-webkit-animation: text-blur-out 4s cubic-bezier(0.550, 0.085, 0.680, 0.530) infinite both;
	        animation: text-blur-out 4s cubic-bezier(0.550, 0.085, 0.680, 0.530) infinite both;
}

 @-webkit-keyframes text-blur-out {
  0% {
    -webkit-filter: blur(0.01);
            filter: blur(0.01);
  }
  100% {
    -webkit-filter: blur(12px) opacity(0%);
            filter: blur(12px) opacity(0%);
  }
}
@keyframes text-blur-out {
  0% {
    -webkit-filter: blur(0.01);
            filter: blur(0.01);
  }
  100% {
    -webkit-filter: blur(12px) opacity(0%);
            filter: blur(12px) opacity(0%);
  }
}
