.knotverse__about {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10rem;
    background: var(--color-footer);

    background: #000;
}

.knotverse__about-icons {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center; 
    flex-direction: row;
}

.knotverse__about-icons img {
    width: 100px;
    margin: 50px;
}


.knotverse__about-heading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0rem 3rem;
}

.knotverse__about-heading h1 {
    -webkit-text-fill-color: transparent;
    color: #fff;
    -webkit-text-stroke: .5px #fff;
    font-family: var(--font-family);
    font-weight: 800;
    text-align: center;
    text-transform: uppercase;
    font-size: 60px;
    line-height: 64px;
    margin: 2rem 0 0rem;
}

.knotverse__about-heading h2 {
    font-family: var(--font-family);
    font-weight: 800;
    text-align: center;
    text-transform: uppercase;
    max-width: 100%;
    font-size: 30px;
    line-height: 40px;
    margin: 1rem 0 2rem;
}

.knotverse__about-heading p { 
    font-family: var(--font-family);
    font-weight: 400;
    text-transform: uppercase;
    text-align: center;
    max-width: 100%;
    font-size: 15px;
    line-height: 30px;
    color: #fff;
    margin: 0rem 0 2rem;
}

@media screen and (max-width: 850px) {
    .knotverse__about {
        padding: 1rem;
    }
    .knotverse__about-icons img {
        width: 40px;
        margin: 10px;
    }
}

@media screen and (max-width: 550px) {
    .knotverse__about {
        padding: 1rem;
    }
    .knotverse__about-heading h1{
        font-size: 35px;
        line-height: 38px;
    }
    .knotverse__about-heading h2{
        font-size: 18px;
        line-height: 20px;
    }
    .knotverse__about-heading p{
        font-size: 12px;
        line-height: 14px;
    }
    .knotverse__about-icons img {
        width: 40px;
        margin: 10px;
    }
}

@media screen and (max-width: 400px) {
    .knotverse__about {
        padding: 0rem;
    }
    .knotverse__about-heading h1{
        font-size: 35px;
        line-height: 38px;
        padding-top: 3rem;
    }
    .knotverse__about-heading h2{
        font-size: 18px;
        line-height: 20px;
    }
    .knotverse__about-heading p{
        font-size: 12px;
        line-height: 14px;
    }
    .knotverse__about-icons img {
        width: 40px;
        margin: 10px;
    }
}