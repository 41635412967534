.knotverse__collections {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 2rem;
}

.knotverse__collections-clowntease {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    max-width: 50%;
    align-items: left;
}

.knotverse__collections-clowntease img {
    filter: drop-shadow(0px 0px 30px rgb(255, 255, 255));
}

.knotverse__collections-clowntext h1 {
    -webkit-text-fill-color: transparent;
    color: #fff;
    -webkit-text-stroke: .5px #fff;
    font-family: var(--font-family);
    font-weight: 800;
    text-align: left;
    text-transform: uppercase;
    font-size: 60px;
    line-height: 64px; 
    margin: 2rem 0 0rem;
}

.knotverse__collections-clowntext h2 {
    font-family: var(--font-family);
    font-weight: 800;
    text-align: left;
    text-transform: uppercase;
    font-size: 30px;
    line-height: 40px;
    margin: .5rem 0 2rem;
}

.knotverse__collections-clowntext p {
    font-family: var(--font-family);
    font-weight: 400;
    text-transform: uppercase;
    text-align: left;
    max-width: 80%;
    font-size: 20px;
    line-height: 30px;
    color: #fff;
    margin: 0rem 0 2rem;
}

.knotverse__collections-opensea button {
    padding: 0.5rem 1rem;
    color: #fff;
    background: #b50000;
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 25px;
    text-transform: uppercase;
    line-height: 25px;
    border: none;
    outline: none;
    cursor: pointer;
}

@media screen and (max-width: 850px) {
    .knotverse__collections-clowntease {
        max-width: 100%;
        align-items: center;
        justify-content: center;
    }
    .knotverse__collections-clowntease img {
        width: 300px;
        height: 300px;
    }
    .knotverse__collections {
        padding: 1rem;
        flex-direction: column;
        align-items: center;
    }
}

@media screen and (max-width: 550px) {
    .knotverse__collections-clowntease {
        display: flex;
        max-width: 100%;
        align-items: center;
        justify-content: center;
    }
    .knotverse__collections {
        padding: 1rem;
        flex-direction: column;
        align-items: center;
    }
    .knotverse__collections-clowntease img {
        width: 300px;
        height: 300px;
    }
    .knotverse__collections-clowntext h1{
        font-size: 35px;
        line-height: 38px;
        text-align: center;
    }
    .knotverse__collections-clowntext h2{
        font-size: 18px;
        line-height: 20px;
        text-align: center;
    }
    .knotverse__collections-clowntext p{
        font-size: 12px;
        line-height: 14px;
        text-align: center;
    }
}

@media screen and (max-width: 400px) {
    .knotverse__collections-clowntease {
        display: flex;
        max-width: 100%;
        align-items: center;
        justify-content: center;
    }
    .knotverse__collections {
        padding: 0rem;
        flex-direction: column;
        align-items: center;
    }
    .knotverse__collections-clowntext {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        max-width: 100%;
        padding-bottom: 5rem;
    }
    .knotverse__collections-clowntease img {
        padding-top: 3rem;
        width: 300px;
        height: 300px;
    }
    .knotverse__collections-clowntext h1{
        font-size: 35px;
        line-height: 38px;
        text-align: center;
    }
    .knotverse__collections-clowntext h2{
        font-size: 18px;
        line-height: 20px;
        text-align: center;
    }
    .knotverse__collections-clowntext p{
        font-size: 12px;
        line-height: 14px;
        text-align: center;
    }
}