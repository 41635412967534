@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@1,900&display=swap');

:root {
  --font-family: 'Montserrat', sans-serif;

  --gradient-text: linear-gradient(89.97deg, #d55e5e 1.84%, #b50000 102.67%);
  --gradient-bar: linear-gradient(103.22deg,  #fff 1.84%, #b50000 102.67%);
  
  --color-bg: #000000;
  --color-footer : #0a0a0a;
  --color-text: #fff;
  --color-subtext: #9f0000;
}